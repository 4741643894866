import React, { FunctionComponent, useState } from "react"
import { Heading, Text, Image, Flex, Spacer } from "@chakra-ui/react"
import { MdxNode } from "../types/PostOverview"
import { cardRadius, colors, shadows } from "../theme/theme"
import ViewCountEye from "../components/ViewCountEye"
import KeywordTags from "../components/KeywordTags"
import LoadingSkeleton from "../components/LoadingSkeleton"
import { formattedDate } from "../utils/formattedDate"

interface IPostHeaderProps {
  data: MdxNode
}

export const PostHeader: FunctionComponent<IPostHeaderProps> = ({ data }) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const blogViews = data.allTotalPageViews.edges.find(elem => {
    if (elem.node.path === data.mdx.frontmatter.path) {
      return elem
    }
  })
  data.mdx.views = blogViews?.node.count

  return (
    <>
      <Heading as="h1" marginBottom="1rem">
        {data.mdx.frontmatter.title}
        {data.mdx.frontmatter.secondaryTitle && (
          <span
            style={{
              fontSize: "1.8rem",
              color: colors.grey.dark,
              display: "block",
            }}
          >
            {data.mdx.frontmatter.secondaryTitle}
          </span>
        )}
      </Heading>
      {data.mdx.frontmatter.tags && (
        <KeywordTags tags={data.mdx.frontmatter.tags ?? ""} />
      )}
      <Flex width="100%" alignItems="center" verticalAlign="middle">
        <Text>
          Written by{" "}
          <a
            style={{ color: colors.blue.blue2 }}
            href={"http://twitter.com/" + data.mdx.frontmatter.authorTwitter}
            target="_blank"
            title={data.mdx.frontmatter.author + "'s Twitter"}
          >
            {data.mdx.frontmatter.author}
          </a>
          . {formattedDate(data.mdx.frontmatter.date)}
        </Text>
        <Spacer />
        <ViewCountEye views={data.mdx.views ? data.mdx.views : 0} />
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        verticalAlign="middle"
        marginBottom="0.4rem"
      >
        <Text color={colors.grey.mediumDark}>
          {data.mdx.frontmatter.time} min read
        </Text>
      </Flex>
      {data.mdx.frontmatter.featuredImage &&
        data.mdx.frontmatter.featuredImage.childImageSharp.fluid.src && (
          <>
            {
              <Image
                src={
                  data.mdx.frontmatter.featuredImage?.childImageSharp.fluid.src
                }
                objectFit="cover"
                width="100%"
                title={data.mdx.frontmatter.title}
                boxShadow={shadows.containerShadow}
                borderRadius={cardRadius}
                style={{ display: `${imageLoaded ? "" : "none"}` }}
                onLoad={() => {
                  setImageLoaded(true)
                }}
              />
            }
            {!imageLoaded && (
              <LoadingSkeleton
                width="100%"
                height="19rem"
                radius="cardRadius"
                styles={{
                  boxShadow: shadows.containerShadow,
                  marginBottom: "0.4rem",
                }}
              />
            )}
          </>
        )}
    </>
  )
}

export default PostHeader
