import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import { Flex, Box, Spinner } from "@chakra-ui/react"
import { MdxNode } from "../types/PostOverview"
import PostHeader from "./PostHeader"
import ProfileBanner from "../components/ProfileBanner/ProfileBanner"
import MdxStyle from "../theme/mdxStyle"
import MorePostsTiles from "../components/MorePostsTiles/MorePostsTiles"
import Seo from "../components/seo"
import { NewsletterSignup } from "../components/NewsletterSignup/NewsletterSignup"
import DonateButton from "../components/BlogFooter/DonateButton"
import BlogFooter from "../components/BlogFooter/BlogFooter"

interface ITemplateProps {
  data: MdxNode
}

export const Template: FunctionComponent<ITemplateProps> = ({ data }) => {
  // Parse date beacuse safari can't handle format yyyy.mm.dd
  const splitDate = data.mdx.frontmatter.date.split(/[^0-9]/)
  const parsedDate = new Date(
    parseInt(splitDate[0]),
    parseInt(splitDate[1]),
    parseInt(splitDate[2])
  )

  const postQuery = graphql`
    query BlogPostQuery($id: String) {
      mdx(id: { eq: $id }) {
        id
        body
        frontmatter {
          path
          title
          secondaryTitle
          author
          authorTwitter
          date
          description
          tags
          time
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allTotalPageViews {
        edges {
          node {
            count
            id
            path
          }
        }
      }
    }
  `

  return (
    <>
      <Seo
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
        secondaryTitle={data.mdx.frontmatter.secondaryTitle}
        previewImage={
          data.mdx.frontmatter.featuredImage?.childImageSharp.fluid.src
        }
        meta={[
          {
            name: data.mdx.frontmatter.title,
            content: data.mdx.frontmatter.description,
          },
        ]}
        type="article"
        url={data.mdx.frontmatter.path}
        published={new Date(parsedDate).toISOString()}
        tags={data.mdx.frontmatter.tags}
      />
      <Layout styles={{ justifyContent: "center" }}>
        <Flex flexDir="column" alignItems="center">
          <Box maxW="35rem" width="100%">
            <PostHeader data={data} />
            <MDXProvider components={MdxStyle}>
              {data && data.mdx && data.mdx.body ? (
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              ) : (
                <Flex
                  padding="3rem 0"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Spinner />
                </Flex>
              )}
            </MDXProvider>
            <BlogFooter data={data} />
            <ProfileBanner styles={{ margin: "2rem 0" }} />
          </Box>
          <Box width="100%">
            <MorePostsTiles skipByTitle={data.mdx.frontmatter.title} />
            <NewsletterSignup />
          </Box>
        </Flex>
      </Layout>
    </>
  )
}

export default Template
