import { Box, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React, { FunctionComponent } from "react"
import { TwitterShareButton, TwitterIcon } from "react-share"
import { colors } from "../../theme/theme"
import { MdxNode } from "../../types/PostOverview"

interface IShareToSocialsProps {
  data: MdxNode
}

const StyledText = styled(Text)`
  color: ${colors.grey.mediumDark};
  margin: auto;
  padding: 0 0.4rem;
`

export const ShareToSocials: FunctionComponent<IShareToSocialsProps> = ({
  data,
}) => {
  const shareTitle = data.mdx.frontmatter.secondaryTitle
    ? data.mdx.frontmatter.title + " - " + data.mdx.frontmatter.secondaryTitle
    : data.mdx.frontmatter.title

  return (
    <Box>
      <TwitterShareButton
        url={"https://www.hendrikgruber.com" + data.mdx.frontmatter.path}
        title={
          `Check out this blog post by @${data.mdx.frontmatter.authorTwitter}: ` +
          shareTitle
        }
        style={{
          width: "auto",
          display: "flex",
        }}
      >
        <TwitterIcon size={40} round />
        <StyledText>Share on Twitter</StyledText>
      </TwitterShareButton>
    </Box>
  )
}

export default ShareToSocials
