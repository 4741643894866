import React, { FunctionComponent } from "react"
import { Button, Box, Image, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { colors, hoverScaleEffect, shadows } from "../../theme/theme"
import { koFiProfile } from "../../utils/routes"
import koFiLogo from "../../assets/images/ko-fi_logo.png"

const StyledButton = styled(Button)`
  background-image: linear-gradient(
    to right,
    ${colors.blue.blue2},
    ${colors.blue.blue5}
  );
  color: ${colors.white};
  height: auto;
  box-shadow: ${shadows.buttonShadow};
  padding: 0.5rem;
  :hover {
    background-image: linear-gradient(
      to right,
      ${colors.blue.blue5},
      ${colors.blue.blue8}
    );
    color: ${colors.white};
  }
`

const AnimatedStyledButton = styled(StyledButton)`
  ${hoverScaleEffect}
`

interface IDonateButtonProps {
  styles?: React.CSSProperties
  disabled?: boolean
  clickedHandler?: () => void
}

export const DonateButton: FunctionComponent<IDonateButtonProps> = ({
  disabled,
  styles,
  clickedHandler,
}) => {
  return (
    <Box margin="0 auto">
      <a href={koFiProfile} target="_blank" title="Ko-fi Donation Page">
        <AnimatedStyledButton
          isDisabled={disabled}
          type="button"
          aria-label="donate"
          style={styles}
          onClick={clickedHandler}
        >
          <Image
            src={koFiLogo}
            height="2.5rem"
            width="auto"
            margin="0"
            title="Hendrik Gruber Logo"
          />
          <Text padding="0 0.5rem">Donate to the blog</Text>
        </AnimatedStyledButton>
      </a>
    </Box>
  )
}

export default DonateButton
