import React, { FunctionComponent } from "react"
import { Divider, Flex } from "@chakra-ui/react"
import ShareToSocials from "./ShareToSocials"
import DonateButton from "./DonateButton"
import { MdxNode } from "../../types/PostOverview"

interface IBlogFooter {
  data: MdxNode
}

export const BlogFooter: FunctionComponent<IBlogFooter> = ({ data }) => {
  return (
    <Flex
      width="100%"
      justifyContent="center"
      marginTop="2rem"
      flexDir="column"
    >
      <ShareToSocials data={data} />
      <Divider marginTop="2rem" />
      <DonateButton styles={{ marginTop: "2rem" }} />
    </Flex>
  )
}

export default BlogFooter
