import React, { FunctionComponent } from "react"
import { HStack, Tag } from "@chakra-ui/react"
import { getTagsArray } from "../utils/getTagsArray"

interface IKeywordTagsProps {
  tags: string
}

const KeywordTags: FunctionComponent<IKeywordTagsProps> = ({ tags }) => {
  const listOfTags = getTagsArray(tags)

  return (
    <HStack spacing={1} display="flex" flexWrap="wrap">
      {listOfTags.map((elem, index) => {
        return (
          <Tag key={index} title={elem} margin="0.2rem 0">
            {elem}
          </Tag>
        )
      })}
    </HStack>
  )
}

export default KeywordTags
