import React from "react"
import { Divider, Heading, ListItem, Text } from "@chakra-ui/layout"
import { colors } from "./theme"
import CodeBlock from "./CodeBlockTheme"
import { OrderedList, UnorderedList } from "@chakra-ui/react"
import styled from "@emotion/styled"

const StyledInlineCode = styled.code`
  padding: 0.15rem 0.4rem;
  ::before,
  ::after {
    content: none;
  }
  word-break: break-all;
`

const mdxHeading = (props: any) => (
  <Heading
    as="h2"
    {...props}
    style={{ fontSize: "1.6rem", marginTop: "1.5rem" }}
  />
)

const mdxHeading2 = (props: any) => (
  <Heading
    as="h3"
    {...props}
    style={{ fontSize: "1.3rem", padding: "0.5rem 0" }}
  />
)

const mdxHeading3 = (props: any) => (
  <Heading
    as="h4"
    {...props}
    style={{ fontSize: "1.1rem", padding: "0.5rem 0" }}
  />
)

const mdxLink = (props: any) => (
  <a target="_blank" style={{ color: colors.blue.blue2 }} {...props} />
)
const mdxParagraph = (props: any) => (
  <Text style={{ color: colors.grey.dark, padding: "0.5rem 0" }} {...props} />
)

const mdxItalic = (props: any) => (
  <Text style={{ fontStyle: "italic", fontWeight: "bold", padding: "0 1rem" }} {...props} />
)

const mdxUnorderedList = (props: any) => (
  <UnorderedList
    style={{ paddingBottom: "0.75rem", paddingTop: "1rem" }}
    {...props}
  />
)
const mdxOrderedList = (props: any) => (
  <OrderedList style={{ paddingBottom: "0.75rem" }} {...props} />
)
const mdxListItem = (props: any) => (
  <ListItem style={{ marginBottom: 0 }} {...props} />
)

export const MdxStyle = {
  h1: mdxHeading,
  h2: mdxHeading2,
  h3: mdxHeading3,
  h4: mdxHeading3,
  h5: mdxHeading3,
  h6: mdxHeading3,
  p: mdxParagraph,
  a: mdxLink,
  thematicBreak: Divider,
  blockquote: mdxItalic,
  pre: (props: any) => <div {...props} />,
  ul: mdxUnorderedList,
  ol: mdxOrderedList,
  li: mdxListItem,
  code: CodeBlock,
  inlineCode: StyledInlineCode,
}

export default MdxStyle
